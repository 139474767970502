import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Terms({ data, show }) {
    const openLInk = (link) => {
        window.open(link, "_blank", "noopener,noreferrer");
    }
    return (
        <>
            <Modal show={show} backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title>Terms And Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data?.overview}<a href={data?.dealerAgreement} target="_blank">here</a>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => openLInk(data.terms)}>
                        Pay Fee
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
